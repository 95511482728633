import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { api } from '../../Common/Constants/apiurl';

const API_TOKEN = localStorage.getItem('token')
const userId = localStorage.getItem('userId');


export const updateMaintaskPermissions = (userRole) => (dispatch) => {
  dispatch(projectSlice.actions.updateMaintaskPermissions({ userRole}));
};

export const updateSubtaskPermissions = (userRole, projectTL) => (dispatch) => {
  dispatch(projectSlice.actions.updateSubtaskPermissions({ userRole, projectTL }));
};

export const updateTimesheetPermissions = (userRole, projectTL) => (dispatch) => {
  dispatch(projectSlice.actions.updateTimesheetPermissions({ userRole, projectTL }));
};


export const fetchProjectAndLeads = createAsyncThunk('project/fetchProjectAndLeads', async (id, { dispatch }) => {
  try {
    const [projectResponse, leadsResponse] = await Promise.all([
      axios.get(api.API_BASE_URL + `fetchspecificOldProject/${id}`, {
        headers: {
          'x-access-token': API_TOKEN,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
      axios.get(api.API_BASE_URL + `fetchspecificProjectResources/${id}/leads`, {
        headers: {
          'x-access-token': API_TOKEN,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    ]);
  
    let Customerresponse;
    if (projectResponse.data[0].customerinfo.length !== 0) {

      var url = api.API_BASE_URL + `fetchspecificCustomer/` + projectResponse.data[0]?.customerinfo[0]?.pro_Customer;
      Customerresponse = await axios.get(url, {
        headers: {
          'x-access-token': API_TOKEN,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    } else {
      Customerresponse = []
    }

    // Extract the relevant data from Customerresponse
    const customerData = Customerresponse.data; // Assuming Customerresponse is an Axios response

    return { projectData: projectResponse.data, leadsData: leadsResponse.data, customerData };
  
  } catch (error) {
    throw error.response.data;
  }
});





export const fetchProjectResources = createAsyncThunk(  
  'project/fetchProjectResources',
  async (id) => {
    try {
        var url = api.API_BASE_URL + `fetchspecificProjectResources/${id}/employees`;
      const response = await axios.get(url, {
        headers: {
          'x-access-token': API_TOKEN,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);


const projectSlice = createSlice({
  name: 'project',
  initialState: {
    projectResources: {data:null,isLoading: false,error: null},
    projectLeads: {data:null,isLoading: false,error: null},
    projectData: {data:null,isLoading: false,error: null},
    projectCustomer : {data:null,isLoading: false,error: null},
    Permissions: {MainTask:{add:false,edit:false,view:true},SubTask:{add:false,edit:false,view:true},TimeSheet:{add:null,edit:null,view:null},TimeSheetApproval:true},
    resource:false,
    teamlead:null,
    projectowner:false,
    approver: ''
  },
  reducers: {
    resetState: (initialState) => {
      // Reset the state to its initial values
      return initialState;
    },
    updateMaintaskPermissions: (state, action) => {
      const { userRole } = action.payload;
      if (userRole === 'SuperAdmin' || state.projectowner) {
        // SuperAdmin or Project Owner: Set all main tasks to true
        state.Permissions.MainTask.add = true;
        state.Permissions.MainTask.edit = true;
        state.Permissions.MainTask.view = true;
      }else {
        state.Permissions.MainTask.add = false;
        state.Permissions.MainTask.edit = false;
        state.Permissions.MainTask.view = true;
      }
    },
    updateSubtaskPermissions: (state, action) => {
      const { userRole, projectTL } = action.payload;
      if (userRole === 'SuperAdmin' || projectTL.length > 0) {
        // SuperAdmin or Project Owner: Set all main tasks to true
        state.Permissions.SubTask.add = true;
        state.Permissions.SubTask.edit = true;
        state.Permissions.SubTask.view = true;
      }
    },
    updateTimesheetPermissions: (state, action) => {
      const { userRole, projectTL } = action.payload;
      if (userRole === 'SuperAdmin' || projectTL.length > 0) {
        // SuperAdmin or Project Owner: Set all main tasks to true
        state.Permissions.TimeSheet.add = true;
        state.Permissions.TimeSheet.edit = true;
        state.Permissions.TimeSheet.view = true;
        state.Permissions.TimeSheetApproval = true;
      } else if(state.projectowner){
        state.Permissions.TimeSheet.add = false;
        state.Permissions.TimeSheet.edit = false;
        state.Permissions.TimeSheet.view = true;
        state.Permissions.TimeSheetApproval = true;
      } else if(state.resource){
        state.Permissions.TimeSheet.add = true;
        state.Permissions.TimeSheet.edit = false;
        state.Permissions.TimeSheet.view = true;
        state.Permissions.TimeSheetApproval = false;
      }
    }
  },
  extraReducers: async (builder) => {
    builder
      .addCase(fetchProjectResources.pending, (state) => {
        state.projectResources.isLoading = true;
        state.projectResources.error = null;
      })
      .addCase(fetchProjectResources.fulfilled, (state, action) => {
        state.projectResources.isLoading = false;
        state.projectResources.data = action.payload;
        const matchingResourceId = action.payload.filter(
          (res) => res._id === userId
        );
        // Dispatch the setLead action to save the matching resource IDs
        state.resource = matchingResourceId ? true: false;
      })
      .addCase(fetchProjectResources.rejected, (state, action) => {
        state.projectResources.isLoading = false;
        state.projectResources.error = action.error.message;
      })
      .addCase(fetchProjectAndLeads.pending, (state) => {
        
      })
      .addCase(fetchProjectAndLeads.fulfilled, (state, action) => {
        // Access the project data and leads data from the action
        const { projectData, leadsData, customerData } = action.payload;

        // Update the state with the fetched data
        state.projectData.isLoading = false;
        state.projectData.data = projectData;
        state.projectLeads.isLoading = false;
        state.projectLeads.data = leadsData;
        state.projectCustomer.data = customerData;
        // Check if project owner is also in the team leads
        // if (userId && action.payload && action.payload.employeeInfo && action.payload.employeeInfo[0]?.emp_id === userId) {
        if (projectData && leadsData) {
          const projectOwnerId = projectData[0]?.employeeInfo[0]?._id;
          const approverId = projectData[0]?.approverInfo[0]?.emp_reportingPerson;
          state.approver = approverId
          const isProjectOwnerInLeads = leadsData.some((lead) => lead.resource_id === projectOwnerId);
          if(localStorage.getItem('role-name') === 'SuperAdmin'){
            state.projectowner = true;
          }
          else if  (userId === projectOwnerId) {
   
          state.projectowner = true
 
          }else {state.projectowner = false;}
          if (isProjectOwnerInLeads && projectOwnerId===userId) {
            
            // Set state.teamlead as an empty array when the project owner is also in team leads
            // state.teamlead = [];
            const matchingResourceId = leadsData.filter(
              (lead) => lead.resource_id === userId
            );
            // Dispatch the setLead action to save the matching resource IDs
            state.teamlead =  matchingResourceId;
          } else {
            const matchingResourceId = leadsData.filter(
              (lead) => lead.resource_id === userId
            );
            // Dispatch the setLead action to save the matching resource IDs
            state.teamlead =  matchingResourceId;


          }
        }else{
         
        }
      })
      .addCase(fetchProjectAndLeads.rejected, (state, action) => {
        // Your rejected logic here if needed
        state.projectData.isLoading = false;
        state.projectData.error = action.error.message;
      });
  },
});

export const { resetState } = projectSlice.actions;

export default projectSlice.reducer;
