import * as React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import MyTaskManage from "./manage";
// import MyTaskDetails from "./detail";
export default function MyTaskCore() {
  return (
    <>
    <Routes>
      <Route path="/" element={<MyTaskLayout />}>       
        <Route index path="" element={<MyTaskManage />} />      
        {/* <Route path="/:id" element={<MyTaskDetails />} />         */}
      </Route>
    </Routes>
    <Outlet />
    </>
  );
}
function MyTaskLayout() {
  return (
    <>
      <Outlet />      
    </>
  );
}