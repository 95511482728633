import React, { Component } from 'react';


export class MyDiv extends Component {
    render() {
        let className = '',
            title = ''
        if (this.props.className !== undefined) {
            className = this.props.className
        }
        if (this.props.title !== undefined) {
            title = this.props.title
        }
        return <div className={className} title={title}>{this.props.children}</div>
    }
}

export class PTag extends Component {
    render() {
        let className = ''
        if (this.props.className !== undefined) {
            className = this.props.className
        }
        return <p className={className}>{this.props.children}</p>
    }
}


export class LabelTag extends Component {
    render() {
        let className = ''

        if (this.props.className !== undefined) {
            className = this.props.className
        }
        return <label className={className}>{this.props.children}</label>
    }
}
export class SpanTag extends Component {
    render() {
        let className = ''

        if (this.props.className !== undefined) {
            className = this.props.className
        }
        return <span className={className}>{this.props.children}</span>
    }
}
export class StrongTag extends Component {
    render() {
        let className = ''

        if (this.props.className !== undefined) {
            className = this.props.className
        }
        return <strong className={className}>{this.props.children}</strong>
    }
}
export class HeadingOne extends Component {
    render() {
        let className = ''

        if (this.props.className !== undefined) {
            className = this.props.className
        }
        return <h1 className={className}>{this.props.children}</h1>
    }
}
export class HeadingTwo extends Component {
    render() {
        let className = ''

        if (this.props.className !== undefined) {
            className = this.props.className
        }
        return <h2 className={className}>{this.props.children}</h2>
    }
}
export class HeadingThree extends Component {
    render() {
        let className = ''

        if (this.props.className !== undefined) {
            className = this.props.className
        }
        return <h3 className={className}>{this.props.children}</h3>
    }
}
export class HeadingFour extends Component {
    render() {
        let className = ''

        if (this.props.className !== undefined) {
            className = this.props.className
        }
        return <h4 className={className}>{this.props.children}</h4>
    }
}
export class HeadingFive extends Component {
    render() {
        let className = ''

        if (this.props.className !== undefined) {
            className = this.props.className
        }
        return <h5 className={className}>{this.props.children}</h5>
    }
}
export class HeadingSix extends Component {
    render() {
        let className = ''

        if (this.props.className !== undefined) {
            className = this.props.className
        }
        return <h6 className={className}>{this.props.children}</h6>
    }

}
export class Loader extends Component {
    render () {
        return  <div class="center">
        <div class="loader">
        <img src={require('../../assets/images/favicon.png')} alt="logo" />
    </div>
    </div>
    }
}