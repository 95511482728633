import * as React from "react";
import { HeadingTwo,  SpanTag } from "./Components";


function NoDataFound() {
    return (
      <SpanTag className="text-center">
        <HeadingTwo className="nodatafound">No Data Found</HeadingTwo>       
      </SpanTag>
    );
  }
  
export default NoDataFound;


