import * as React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import './App.scss';
import { Routes, Route} from "react-router-dom";
import TemplateCore from "./pages/template";
import TemplateAudit from "./pages/Audit/template";
import CoreForgotPassword from "./pages/Auth/forgot-password";
import CoreUpdatePassword from "./pages/Auth/update-password";
import NewProjectsCore from "./pages/NewProjects";

const NoMatch = React.lazy(() => import("./pages/Common/error"));
const EmployeeCore = React.lazy(() => import("./pages/Employee"));
const SalaryCore = React.lazy(() => import("./pages/Salary"));
const DashboardCore = React.lazy(() => import("./pages/MainDashboard"));
const CoreLogin = React.lazy(() => import("./pages/Auth/login"));
const CoreFaceAuth = React.lazy(() => import("./pages/Auth/face-auth"));
const InventoryCore = React.lazy(() => import("./pages/Inventory"));
const MasterCore = React.lazy(() => import("./pages/Master"));
const SettingsCore = React.lazy(() => import("./pages/Settings"));
const CoreChangepassword = React.lazy(() => import("./pages/Auth/changepassword"));
const ProfileCore = React.lazy(() => import("./pages/Profile"));
const LeaveCore = React.lazy(() => import("./pages/Leave"));
const CustomerCore = React.lazy(() => import("./pages/Customer"));
const ProjectsCore = React.lazy(() => import("./pages/Projects"));
const CoreReports = React.lazy(() => import("./pages/Reports"));
const AuditCore = React.lazy(() => import("./pages/Audit"));
const TicketingCore = React.lazy(() => import("./pages/Tickets"));


function App() {
  return (
    <Routes>
      <Route path="/" element={<CoreLogin />} />
      <Route path="/login" element={<CoreLogin />} />
      <Route path="face-auth" element={<React.Suspense fallback={<>...</>}><CoreFaceAuth /></React.Suspense>}/>            
      <Route path="forgotpassword" element={<CoreForgotPassword />} />
      <Route path="updatepassword/:id" element={<CoreUpdatePassword />} />
      <Route element={<TemplateCore />}>      
        <Route index path="dashboard/*" element={<React.Suspense fallback={<>...</>}><DashboardCore /></React.Suspense>}/>
        <Route path="employee/*"  element={<React.Suspense fallback={<>...</>}><EmployeeCore /></React.Suspense>}/>
        <Route path="finance/*" element={<React.Suspense fallback={<>...</>}><SalaryCore /></React.Suspense>} />  
        <Route path="master/*" element={<React.Suspense fallback={<>...</>}><MasterCore /></React.Suspense>} />  
        <Route path="inventory/*" element={<React.Suspense fallback={<>...</>}><InventoryCore /></React.Suspense>} />     
        <Route path="changepassword/*" element={<React.Suspense fallback={<>...</>}><CoreChangepassword /></React.Suspense>} /> 
        <Route path="profile/*" element={<React.Suspense fallback={<>...</>}><ProfileCore /></React.Suspense>} /> 
        <Route path="leave/*" element={<React.Suspense fallback={<>...</>}><LeaveCore /></React.Suspense>} />
        <Route path="settings/*" element={<React.Suspense fallback={<>...</>}><SettingsCore /></React.Suspense>} />
        <Route path="customer/*" element={<React.Suspense fallback={<>...</>}><CustomerCore /></React.Suspense>} />
        <Route path="projects/*" element={<React.Suspense fallback={<>...</>}><ProjectsCore /></React.Suspense>} />
        <Route path="new-projects/*" element={<React.Suspense fallback={<>...</>}><NewProjectsCore /></React.Suspense>} />
        <Route path="reports/*" element={<React.Suspense fallback={<>...</>}><CoreReports /></React.Suspense>} />
        <Route path="tickets/*" element={<React.Suspense fallback={<>...</>}><TicketingCore /></React.Suspense>} />
        <Route path="*" element={<NoMatch />} />
      </Route>
      <Route element={<TemplateAudit />}>   
        <Route index path="audit/*" element={<React.Suspense fallback={<>...</>}><AuditCore /></React.Suspense>}/>
      </Route>
    </Routes>
  );
}

export default App;

