import { createSlice } from '@reduxjs/toolkit';


const userSlice = createSlice({
  name: 'project',
  initialState: {
    "emp_firstName" : "",
    "emp_Designation" : ""
  },
  reducers: {
    resetState: (initialState) => {
      return initialState;
    },
    addUserState : (state,action) => {
        const { emp_firstName, emp_Designation } = action.payload;
        console.log("function iis calling in redux",emp_firstName,emp_Designation)
        if (emp_firstName && emp_Designation) {
          state.emp_firstName = emp_firstName;
          state.emp_Designation = emp_Designation;
        }
    }
  },
});

export const { resetState, addUserState } = userSlice.actions;

export default userSlice.reducer;
