import React, { useState, useEffect } from 'react';
import { TableBody, Table, TableContainer, TableHead, TableRow, TextField, TableCell, IconButton, Tooltip, Drawer, MenuItem, Button } from "@mui/material";
import { Row, Col, Card } from "react-bootstrap";
import { SpanTag, LabelTag, MyDiv } from "../Common/Components";
import { Link } from "react-router-dom";
import { Add, Edit, FilterAlt, FilterAltOff } from "@mui/icons-material";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { api } from '../Common/Constants/apiurl';
import axios from 'axios';
import Avatar from "react-avatar";
import swal from "sweetalert2";
import NoDataFound from '../Common/nodatafound';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ApproverDepartments, dateFormat } from '../Common/Constants/staticjson';
import ReactPaginate from 'react-paginate';




const API_BASE_URL = api.API_BASE_URL;

export default function ProjectTasks({ CoreProjectId }) {
    const API_TOKEN = localStorage.getItem("token");
        const [projectTask, setProjectTask] = useState([]);
        const [resourceData, setResourceData] = useState([]);
        const [pageCount, setPageCount] = useState(1);
        const [searchFilters, setSearchFilters] = useState({ employee: '', department: '', startDate: moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'),endDate : moment(new Date()).format('YYYY-MM-DD') });
        const [currentPage, setCurrentPage] = useState(0);
        const [subTaskApproveDrawerState, setSubTaskApproveDrawerState] = React.useState(false);
        const [drawerData, setDrawerData] = useState({
            id: "",
            task_action: "",
            emp_firstName: "",
        });

        const ranges = {
            Today: [moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')],
            Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
          };
    
        // Load tasks only on component mount
        useEffect(() => {
            loadAllResources();
        }, []);
    
        // Load tasks when searchFilters change
        useEffect(() => {
            loadTasks();
        }, [searchFilters,currentPage]);
    
        const resetFilters = () => {
            setSearchFilters({
                department: '',
                startDate: moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'),
                endDate: moment(new Date()).format('YYYY-MM-DD'),
                employee: '',
            });
        };  

    
        const loadTasks = async () => {
            const { employee, department, startDate, endDate } = searchFilters;
    
            var url = API_BASE_URL + `fetch-task-activities/${CoreProjectId}?page=${currentPage}&dept=${department}&emp=${employee}&startDate=${startDate}&endDate=${endDate}`;
            try {
                const response = await axios.get(url, { headers: { 'x-access-token': `${API_TOKEN}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } });
                setProjectTask(response.data.fetchedTasks);
                setPageCount(response.data.totalPages);
            } catch (error) {
                swal.fire({
                    text: error.response?.data || "Error fetching tasks",
                    icon: "error",
                });
            }
        };
    
        const loadAllResources = async () => {
            var url = API_BASE_URL + `fetchProjectResource`;
            try {
                const response = await axios.get(url, { headers: { 'x-access-token': `${API_TOKEN}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } });
                setResourceData(response.data);
            } catch (error) {
                swal.fire({
                    text: error.response?.data || "Error fetching resources",
                    icon: "error",
                });
            }
        };

        const handleDateChange = (ranges) => {
            const { startDate, endDate } = ranges;
            setSearchFilters({...searchFilters,
              startDate: moment(startDate).format('YYYY-MM-DD'),
              endDate: moment(endDate).format('YYYY-MM-DD'),
            });
          };

          const handlePageChange = (selectedObject) => {
            const selectedPage = selectedObject.selected;
            setCurrentPage(selectedPage);
        };


    return (
        <>
            <Card className="LayoutCard">
                <Card.Header>
                    <Row>
                        <Col md={3} className="SearchTextbox">
                            <TextField className='w-100'  select id="employee" label="Employee" value={searchFilters.employee} onChange={(e) => setSearchFilters({ ...searchFilters, employee: e.target.value })} variant="standard">
                                {resourceData.map((resources) => (
                                    <MenuItem key={resources._id} value={resources._id}>{resources.emp_firstName}</MenuItem>
                                ))}
                            </TextField>
                        </Col>
                        <Col md={3} className="SearchTextbox">
                            <TextField className='w-100' select id="department" label="Department" value={searchFilters.department} onChange={(e) => setSearchFilters({ ...searchFilters, department: e.target.value })} variant="standard">
                                {ApproverDepartments.map((resources) => (
                                    <MenuItem key={resources.value} value={resources.value}>{resources.label}</MenuItem>
                                ))}
                            </TextField>
                        </Col>
                        <Col md={2}>                           
                        <DateRangePicker onApply={(e, picker) => handleDateChange(picker)} initialSettings={{ ranges }}>
                              <input type="text" value={`${moment(searchFilters.startDate).format(dateFormat)}-${moment(searchFilters.endDate).format(dateFormat)}`} className="form-control data-range-picker" />
                            </DateRangePicker>
                        </Col>
                        <Col>
                            <Button onClick={resetFilters} className="btn secondary-btn mt-2">Reset</Button>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <MyDiv className="GeneralTable pxy-0 mt-0 ">
                        <TableContainer>
                            <Table className='bgGrey mt-0' aria-label="Milestone table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Employee</TableCell>
                                        <TableCell align="left">Department</TableCell>
                                        <TableCell align="left">Worked Hours</TableCell>
                                        <TableCell align="left">Worked Date</TableCell>
                                        <TableCell align="left" width={300}>Description</TableCell>
                                        <TableCell align="left">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {projectTask?.length
                                        ? projectTask.map((item) => (
                                            <React.Fragment key={item._id}>
                                                <TableRow>
                                                    <TableCell align="left" component="th" scope="row" >
                                                        {item.taskaddedemp ? item.taskaddedemp[0] : null}
                                                    </TableCell>
                                                    <TableCell>{item.tms_Task_Department}</TableCell>
                                                    <TableCell>{item.tms_Task_Worked_Hours}</TableCell>
                                                    <TableCell>{moment(item.tms_Task_Entry_Date).format("MMMM Do YYYY")}</TableCell>
                                                    <TableCell><SpanTag className="projectNotes">{item.tms_Task_Desc}</SpanTag></TableCell>
                                                    <TableCell>
                                                        {/* {localStorage.getItem("role-name") === "SuperAdmin" ? (
                                                            <><Tooltip title="Approval">
                                                                <IconButton aria-label="fingerprint" color="success"
                                                                    onClick={(e) => subTaskApproveEditId(item._id, "Approve")}
                                                                >
                                                                    <CheckCircleIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                                <Tooltip title="Decline">
                                                                    <IconButton aria-label="fingerprint" color="warning"
                                                                        onClick={(e) => subTaskApproveEditId(item._id, "Decline")}
                                                                    >
                                                                        <CancelIcon />
                                                                    </IconButton>
                                                                </Tooltip></>
                                                        ) : (<></>)} */}
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment >
                                        )) : null || <TableRow><TableCell colSpan={9}><NoDataFound /></TableCell></TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <MyDiv className="reactPaginate d-flex justify-content-center mt-4">
                                <ReactPaginate pageCount={pageCount} pageRange={1} marginPagesDisplayed={2} onPageChange={handlePageChange}
                                    containerClassName={'pagination'} previousLinkClassName={'page'} breakClassName={'page'} nextLinkClassName={'page'}
                                    pageClassName={'page'} disabledClassName={'disabled'} activeClassName={'active'} forcePage={currentPage}
                                />
                            </MyDiv>
                    </MyDiv>
                </Card.Body>
            </Card>
            {/* <Drawer anchor="right" open={subTaskApproveDrawerState} onClose={() => handleSubTaskApproveDrawerToggle(false)} >
                <FormApproveSubTasks handleClose={updateSubTaskApproveDrawer} subTaskEditId={drawerData} taskName={"timesheet"} />
            </Drawer> */}
        </>
    )
}
