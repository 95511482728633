import React, { useState, useEffect, startTransition } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { MyDiv, PTag } from "./Components";
import Avatar from "react-avatar";
import { RiUserHeartLine, RiLockPasswordLine, RiLogoutCircleLine } from "react-icons/ri";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import axios from 'axios';
import { api } from "../Common/Constants/apiurl";
import swal from "sweetalert2";
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addUserState, resetState } from '../ReduxToolkit/reducer/userReducer';

const API_BASE_URL = api.API_BASE_URL;

function Header() {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const user = useSelector(state=> state.user);
  //const [empId, setEmpId] = React.useState()


  useEffect(() => {
    if ((localStorage.getItem("token") === null) && (localStorage.getItem("role") === null)) {
      navigate("/login");
    } else {
      async function init() {
        const data = await localStorage.getItem('userId')
        // setEmpId(data);
        if(!user.emp_firstName){
          loadUser(data);
        }
      }
      init();
  
    }
  }, [navigate]);

const DateTime = () => {
  var [date, setDate] = useState(new Date());

  useEffect(()=> {
    var timer = setInterval(()=> setDate(new Date()),1000)
    return function cleanup() {
      clearInterval(timer)
    }
  });

  return (
    <MyDiv className="HeaderInfo hide-for-small"><span>{date.toLocaleDateString()},{date.toLocaleTimeString()}</span></MyDiv>
  )
}


  const logoutCore = () => {
    startTransition(() => {
      localStorage.clear();
      dispatch(resetState());
      navigate("/login");
      // window.location.href = "/login";
    });
  }
  //var empId = localStorage.getItem("userId")


  const loadUser = async (data) => {
    // alert('hai');
    var UserId = localStorage.getItem('userId');
    var Token = localStorage.getItem('token');
    var url = API_BASE_URL + `specificprofile/` + UserId;
    await axios.get(url, { headers: { 'x-access-token': `${Token}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } })
      .then(res => {
        if(res.data.length){
          const {emp_firstName,emp_Designation} = res.data[0]
          dispatch(addUserState({emp_firstName,emp_Designation}));
        }
      }).catch((err) => {
        swal.fire({
          text: err.response.data,
          icon: "error",
          type: "error"
        });
        if (err.response.data === "Invalid Token") {
          // navigate("/login");
          window.location.href = "/login";
        } else {
          //navigate("/login");
        }
      });
  }

  var RolePermission = JSON.parse(localStorage.getItem('role'));
  return (
    <>
      <header>
        <Row>
          <Col xs={6} md={4}>
            <Link to="/dashboard">
              <img src={require('../../assets/images/logo.png')} alt="logo" />
            </Link>
          </Col>

          <Col xs={6} md={8} className="text-end right-menu" >
            {RolePermission && RolePermission.HighLevelAproval && RolePermission.HighLevelAproval.view === "1" ?
              <Link to="/audit" className="btn primary-btn me-2 hide">
                Audit
              </Link> : null}
              <DateTime/>
              <React.Fragment>
                <Avatar round="50px" size="40" name={user.emp_firstName} src="" />
                <MyDiv className="ProfileArea">
                  <DropdownButton title={user.emp_firstName}>
                    <Dropdown.Item as={Link} to="/changepassword"><RiLockPasswordLine /> Change Password</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/profile"><RiUserHeartLine /> My Profile</Dropdown.Item>
                    <Dropdown.Item onClick={logoutCore}><RiLogoutCircleLine /> Logout</Dropdown.Item>
                  </DropdownButton>
                  <PTag className="hide-for-small">{user.emp_Designation}</PTag>
                </MyDiv>
              </React.Fragment>
 
          </Col>

        </Row>
      </header>
    </>
  );
}

export default Header;


