// export const api = {
//     // API_BASE_URL : 'http://192.168.0.121:8084/'
//     API_BASE_URL : 'http://103.240.24.218:8085/'
// }

export const api = {
    //API_BASE_URL : 'http://192.168.0.26:8084/', 
    //API_BASE_URL : 'http://192.168.0.58:8084/',
    //API_BASE_URL : 'http://192.168.0.71:8085/',
    //API_BASE_URL : 'http://192.168.0.151:8084/',
//Live
API_BASE_URL : 'http://103.240.24.218:8085/',

    myToken: localStorage.getItem("token"),
    myId: localStorage.getItem("userId"), 
    HRMSDateFormat: "DD-MM-YYYY"
}