import React, { useState, useEffect } from 'react';
import { TableBody, Table, TableContainer, TableHead, TableRow, TableCell, IconButton, Drawer, Button, TextField, MenuItem,Tooltip } from "@mui/material";
import { Row, Col, Card, Badge } from "react-bootstrap";
import { HeadingFour, MyDiv, SpanTag, HeadingTwo } from "../../Common/Components";
import { api } from "../../Common/Constants/apiurl";
import axios from 'axios';
import swal from "sweetalert2";
import NoDataFound from '../../Common/nodatafound';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import moment from 'moment';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { useNavigate, Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// import FormTimsheet from './flextaskForm';
import { Edit, Delete } from '@mui/icons-material';
import ReactPaginate from 'react-paginate';
import ProjectTimeSheetForm from '../ProjectTimeSheetForm';
import { ApproverDepartments } from '../../Common/Constants/staticjson';


const API_BASE_URL = api.API_BASE_URL;

export default function MyTaskManage() {
    const API_TOKEN = localStorage.getItem("token");
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0);
    const [projectTask, setProjectTask] = useState([]);
    const [searchFilters, setSearchFilters] = useState({ project: '', department: '', relDate: '' });
    const [timeSheetDrawerState, setTimeSheetDrawerState] = React.useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [projectList, setProjects] = useState([]);
    const [data, setData] = useState({id:"", tms_Task_Brand:""});

    useEffect(() => {
        loadMyTask();
    }, [currentPage, searchFilters]);

    const resetFilters = () => {
        setSearchFilters({
            department: '',
            relDate: '',
            project: '',
        });
    };

    const loadMyTask = async () => {
        const { project, department, relDate } = searchFilters;
        const [startDate, endDate] = relDate || [null, null];
        const formattedStartDate = startDate && moment(startDate).isValid() ? moment(startDate).format('YYYY-MM-DD') : '';
        const formattedEndDate = endDate && moment(endDate).isValid() ? moment(endDate).format('YYYY-MM-DD') : '';

        var url = API_BASE_URL + `fetch-specific-employee-overall-task-details?page=${currentPage}&dept=${department}&project=${project}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        try {
            const response = await axios.get(url, { headers: { 'x-access-token': `${API_TOKEN}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } });
            setProjectTask(response.data);
            setPageCount(response.data.totalPages);
        } catch (error) {
            swal.fire({
                text: error.response?.data || "Error fetching tasks",
                icon: "error",
            });
        }
    };
    const loadProjects = async () => {
        var url = API_BASE_URL + `fetchprojects`;
        axios.post(url, {}, { headers: { 'x-access-token': `${API_TOKEN}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(res => {
                setProjects(res.data)
            },
                (error) => {
                    swal.fire({
                        text: error.response.data,
                        icon: "error",
                        type: "error"
                    });
                })
    }

    const deleteTimeSheet = async (id, tms_Task_Brand) => {
         var url =API_BASE_URL +  `delete-emp-daily-task/${id}/${tms_Task_Brand}`;
        var method =  'patch';
        axios({ method, url, data, headers: { 'x-access-token': `${API_TOKEN}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.status === 200) {
                    swal.fire({
                        text: "Successfully Deleted",
                        icon: "success",
                        type: "success"
                    });
                    loadMyTask();
                }               
            })
            .catch(error => {
                swal.fire({
                    text: error.response.data,
                    icon: "error",
                    type: "error"
                });
            });
    }

    useEffect(() => {
        loadProjects();
    }, []);

    const newTimeSheetDrawer = (props) => {
        setData();
        timeSheetDrawerState === false ? setTimeSheetDrawerState(true) : setTimeSheetDrawerState(false)
    }
    const editTimeSheet = (_id, tms_Task_Brand) => {
        console.log('tms_Task_Brand', tms_Task_Brand)
        let newData = {...data}
        newData['id'] = _id;
        newData['tms_Task_Brand'] = tms_Task_Brand;
        setData(newData);
        timeSheetDrawerState === false ? setTimeSheetDrawerState(true) : setTimeSheetDrawerState(false)
    }
    const updateTimeSheetDrawer = () => {
        timeSheetDrawerState === false ? setTimeSheetDrawerState(true) : setTimeSheetDrawerState(false)
        loadMyTask();
    };

    const handlePageChange = (selectedObject) => {
        const selectedPage = selectedObject.selected;
        setCurrentPage(selectedPage);
    };
    return (
        <>
            <Row className="GeneralHeading">
                <Col md={6} >
                    <HeadingTwo><Link className="arrow-btn" onClick={() => navigate(-1)}><ArrowBackIosIcon /></Link>Timesheet</HeadingTwo>
                </Col>
                <Col md={6} className="text-end">
                    <Button onClick={(e) => newTimeSheetDrawer()} className="btn primary-btn lvbtn mx-2"> Fill TimeSheet </Button>
                </Col>
            </Row>
            <Row className="row GeneralTable">
                <Card className="LayoutCard">
                    <Card.Header>
                        <Row>
                            <Col md={3} className="SearchTextbox">
                                <TextField className='w-100' select id="project" label="Project" value={searchFilters.project} onChange={(e) => setSearchFilters({ ...searchFilters, project: e.target.value })} variant="standard">
                                    {projectList.map((project) => (
                                        <MenuItem value={project._id}>{project.pro_name} / {project.pro_code}</MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                            <Col md={3} className="SearchTextbox">
                                <TextField className='w-100' select id="department" label="Department" value={searchFilters.department} onChange={(e) => setSearchFilters({ ...searchFilters, department: e.target.value })} variant="standard">
                                    {ApproverDepartments.map((resources) => (
                                        <MenuItem key={resources.value} value={resources.value}>{resources.label}</MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                            <Col md={3}>
                                <DateRangePicker className="mt-3" value={searchFilters.relDate} onChange={(selectedDate) => { setSearchFilters({ ...searchFilters, relDate: selectedDate }); }} />
                            </Col>
                            <Col md={3}>
                                <Button onClick={resetFilters} className="btn secondary-btn mt-3">Reset</Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>

                        <MyDiv className="GeneralTable pxy-0 mt-0 ">
                            <TableContainer>
                                <Table className='bgGrey mt-0' aria-label="Milestone table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Project / Ticket</TableCell>
                                            <TableCell align="left">Department</TableCell>
                                            <TableCell align="left">Brand</TableCell>
                                            <TableCell align="left">Description</TableCell>
                                            <TableCell align="center">Worked Date</TableCell>
                                            <TableCell align="center">Actual Hours</TableCell>
                                            <TableCell align="center">View Task</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {projectTask.fetchedTasks?.length
                                            ? projectTask.fetchedTasks.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <TableRow className={item.tms_Ticket_No ? 'ticketRow' : 'projectRoe'}>
                                                        <TableCell>{item.pro_name ? item.pro_name : item.tms_Ticket_No}</TableCell>
                                                        <TableCell>{item.tms_Task_Department}</TableCell>
                                                        <TableCell>{item.tms_Task_Brand}</TableCell>
                                                        <TableCell width={300}><SpanTag className="projectNotes">{item.tms_Task_Desc}</SpanTag></TableCell>
                                                        <TableCell align="center">{item.tms_Task_Entry_Date ? moment(item.tms_Task_Entry_Date).format('DD-MM-YYYY') : null}</TableCell>
                                                        <TableCell align="center">{item.tms_Task_Worked_Hours}</TableCell>
                                                        <TableCell align="center">
                                                            <Tooltip title="View Task">
                                                                <IconButton aria-label="fingerprint" color="secondary"  onClick={()=> editTimeSheet(item._id, item.tms_Task_Brand)}  >                                                                  
                                                                    <Edit />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete Task">
                                                                <IconButton aria-label="fingerprint" color="danger"  onClick={()=> deleteTimeSheet(item._id, item.tms_Task_Brand)}  >                                                                  
                                                                    <Delete />
                                                                </IconButton>
                                                            </Tooltip>

                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment >
                                            )) : null || <TableRow><TableCell colSpan={10}><NoDataFound /></TableCell></TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <MyDiv className="reactPaginate d-flex justify-content-center mt-4">
                                <ReactPaginate pageCount={pageCount} pageRange={1} marginPagesDisplayed={2} onPageChange={handlePageChange}
                                    containerClassName={'pagination'} previousLinkClassName={'page'} breakClassName={'page'} nextLinkClassName={'page'}
                                    pageClassName={'page'} disabledClassName={'disabled'} activeClassName={'active'} forcePage={currentPage}
                                />
                            </MyDiv>
                        </MyDiv>
                    </Card.Body>
                </Card>
            </Row>
            <Drawer anchor="right" open={timeSheetDrawerState} onClose={() => newTimeSheetDrawer(false)} >
               
                <ProjectTimeSheetForm handleClose={updateTimeSheetDrawer} taskEditId={data} />
            </Drawer>
        </>
    )
}