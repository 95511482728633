import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { HeadingFour, HeadingTwo, MyDiv, SpanTag } from "../Common/Components";
import Avatar from "react-avatar";
import { api } from "../Common/Constants/apiurl";
import axios from 'axios';
import swal from "sweetalert2";
import { TableBody, Table, TableContainer, TableHead, TableRow, TableCell, Box, IconButton, Tooltip, Drawer, Button, TextField,InputAdornment } from "@mui/material";
import { Row, Col, Badge } from "react-bootstrap";
import { Edit } from "@mui/icons-material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import FormProjects from '../NewProjects/form';
import NoDataFound from '../Common/nodatafound';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import { MdClear } from "react-icons/md";
import ReactPaginate from 'react-paginate';


const API_BASE_URL = api.API_BASE_URL;
const Rolename = localStorage.getItem("role-name");

export default function ManageNewProjects() {
    const API_TOKEN = localStorage.getItem("token");
    const loggedEmpId = localStorage.getItem("userId");
    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState([]);
    const [data, setData] = useState('');
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const [module, setModule] = React.useState([{
        ProjectManagement: '',
    }
    ])

    /* Search Module */
    const [searchdata, setSearchData] = useState({
        transcript: "",
    })
    useEffect(() => {
        loadProjects();
        async function init() {
            const data = await localStorage.getItem('role');
            setModule(JSON.parse(data));
        }
        init();
    }, []);

    const loadProjects = async (page, val) => {
        setLoading(true);
        var url = API_BASE_URL + `fetchprojectslimited?page=${page + 1}`;
        const data = { searchTerm: val === "" ? "":searchdata.transcript };
        axios.post(url, data, { headers: { 'x-access-token': `${API_TOKEN}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(res => {
                setPosts(res.data.data);
                setPageCount(res.data.totalPages);
            },
                (error) => {
                    swal.fire({
                        text: error.response.data,
                        icon: "error",
                        type: "error"
                    });
                })
        setLoading(false);
    }

    const [projectDrawerState, setProjectsDrawerState] = React.useState(false);
    const handleProjectsDrawerToggle = (props) => {
        setData();
        projectDrawerState === false ? setProjectsDrawerState(true) : setProjectsDrawerState(false)
    }

    const projectEditId = (_id) => {
        projectDrawerState === false ? setProjectsDrawerState(true) : setProjectsDrawerState(false)
        setData(_id);
    }

    const updateDrawer = () => {
        projectDrawerState === false ? setProjectsDrawerState(true) : setProjectsDrawerState(false)
        loadProjects();
    };


    function searchhandle(e) {
        const searchnewdata = { ...searchdata }
        searchnewdata[e.target.id] = e.target.value
        setSearchData(searchnewdata)
    }

    function searchsubmit(e) {
        e.preventDefault();
        loadProjects()
    }

  /*Pagination*/
const handlePageChange = (selectedObject) => {
    const selectedPage = selectedObject.selected;
    setCurrentPage(selectedPage); // Update currentPage state with the selected page number
    loadProjects(selectedPage); // Load projects with the selected page number
};

useEffect(() => {
    // Load projects initially when component mounts
    loadProjects(currentPage);
}, [currentPage]);
/*Pagination*/

    let navigate = useNavigate();

    return (<>
            <Row className="GeneralHeading">
                <Col md={6} >
                    <HeadingTwo><Link className="arrow-btn" onClick={() => navigate(-1)}><ArrowBackIosIcon /></Link>Manage Projects</HeadingTwo>
                </Col>
                <Col md={6} className="text-end">
                    {module.ProjectManagement && module.ProjectManagement.add === "1" ?   <Button onClick={(e) => handleProjectsDrawerToggle()} className="btn primary-btn">Add Projects</Button>  : ""}
                </Col>
            </Row>
            <Row className='SearchBar GeneralHeading mt-15'>
                <Col md={12} >
                    <form onSubmit={(e) => searchsubmit(e)}>
                        <Row>
                            <Col md={6} className="SearchTextbox">
                                <TextField
                                    className='textarea'
                                    id="transcript"
                                    placeholder="Search"
                                    variant="standard"
                                    value={searchdata.transcript}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                          event.preventDefault(); // Prevent form submission on Enter key press
                                          // Call your form's onSubmit function here
                                          loadProjects()
                                        }
                                    }}
                                    onChange={(event) => { searchhandle(event)}}
                                    InputProps={{ endAdornment : searchdata.transcript && <><InputAdornment style={{ right: 12, position: "absolute" }} position='end'><button onClick={() => {setSearchData({
                                        transcript: "",
                                    }); loadProjects(currentPage, "") }}><MdClear/></button></InputAdornment></>
                                    }}
                                 />
                            </Col>
                            <Col md={6} className="text-start">
                                <button type='submit' className="btn primary-btn add-cta search mx-1">Search</button> &nbsp; &nbsp;
                                {/* <button  className="btn primary-btn add-cta rounded-btn search mx-1" onClick={SpeechRecognition.startListening}>{listening ? <MdOutlineRecordVoiceOver/> : <MdVoiceOverOff />}</button> &nbsp; &nbsp;
                                <button className="btn primary-btn add-cta search mx-1" onClick={resetTranscript}>Reset</button> */}
                                {/*<p>listening... {listening ? "on" : "off"}</p>      
                                        <button onClick={SpeechRecognition.stopListening}>Stop</button> */}
                            </Col>
                        </Row>
                    </form>
                </Col>
            </Row>
            <Row className="row GeneralTable">
                {loading ? (
                    <HeadingFour className="text-center">Loading...</HeadingFour>) : <>
                    <TableContainer>
                        <Table className='bgGrey' aria-label="Projects table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Projects Name</TableCell>
                                    <TableCell align="left">Customer Name</TableCell>
                                    <TableCell align="left">Code</TableCell>
                                    <TableCell align="left">Project Owner <sup>PM/PL</sup></TableCell>
                                    <TableCell align="left">Start Date</TableCell>
                                    <TableCell align="left">End Date</TableCell>
                                    <TableCell align="left">Cost Center</TableCell>
                                    <TableCell align="left">Status</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {posts?.length
                                    ? posts.map((item) => (
                                        <React.Fragment key={item._id}>
                                            <TableRow >
                                                <TableCell align="left" component="th" scope="row" className="tableAvathar">
                                                    <Avatar round="50px" size="40" name={item.pro_name} src={`/${item.image}`} />
                                                    {module.ProjectManagement && (item.pro_owner === loggedEmpId || Rolename==="SuperAdmin" || module.ProjectManagement.view === "1") ?  <Link to={`${item._id}`}> {item.pro_name}</Link> :  <SpanTag className="ms-2">{item.pro_name}</SpanTag>  }
                                                   
                                                </TableCell>
                                                <TableCell align="left">{item.cus_name}</TableCell>
                                                <TableCell align="left">{item.pro_code}</TableCell>
                                                <TableCell align="left">
                                                    {item.pro_owner_name}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {item.pro_startDate}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {item.pro_endDate}
                                                </TableCell>

                                                <TableCell align="left">
                                                    {item.pro_costCenter}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <MyDiv> <Badge bg='success' text="light">{item.pro_status}</Badge>  </MyDiv>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Box className="custom-flex">
                                                        {(module.ProjectManagement && module.ProjectManagement.edit === "1") ? 
                                                            <Tooltip title="Edit">
                                                                <IconButton aria-label="fingerprint" color="success" onClick={(e) => projectEditId(item._id)} >
                                                                    <Edit />
                                                                </IconButton>                                                         
                                                            </Tooltip>   
                                                        : "" }                                                    
                                                        {module.ProjectManagement && (item.pro_owner === loggedEmpId || Rolename==="SuperAdmin" || module.ProjectManagement.view === "1") ?
                                                        <Link to={`${item._id}`}>
                                                            <Tooltip title="View Profile">
                                                                <IconButton aria-label="fingerprint" color="secondary">
                                                                    <VisibilityIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Link>   : "" }
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    )) : <><TableRow><TableCell colSpan={8}><NoDataFound /></TableCell></TableRow></>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <MyDiv className="reactPaginate d-flex justify-content-center mt-4">
                    <ReactPaginate pageCount={pageCount} pageRange={1} marginPagesDisplayed={2} onPageChange={handlePageChange} containerClassName={'pagination'} previousLinkClassName={'page'} breakClassName={'page'} nextLinkClassName={'page'} pageClassName={'page'} disabledClassName={'disabled'} activeClassName={'active'} forcePage={currentPage} />
                    </MyDiv>
                </>
                }
            </Row>
            <Drawer anchor="right" open={projectDrawerState} onClose={() => handleProjectsDrawerToggle(false)} >
                <FormProjects handleClose={updateDrawer} projectEditId={data} props />
            </Drawer>
            </>
    );
}

