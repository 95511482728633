import React, { useState, useEffect } from 'react';
import { HeadingFour, LabelTag, MyDiv } from "../Common/Components";
import { Row, Col, Card, Spinner, Button } from "react-bootstrap";
import { TextField, MenuItem } from '@mui/material';
import axios from 'axios';
import { projectStatus, projectType, projectContractStatus, projectBillingType } from '../Common/Constants/staticjson';
import swal from "sweetalert2";
import { api } from "../Common/Constants/apiurl";

const API_BASE_URL = api.API_BASE_URL;
const API_TOKEN = api.myToken;

function FormProjects({ handleClose, projectEditId }) {

    const [employees, setEmployees] = useState([]);
    const [btnLoading, setbtnLoading] = useState(false);
    const [customerData, setCustomerData] = useState([]);
    const [data, setData] = useState({
        pro_name: "",
        // pro_code: "",
        pro_description: "",
        pro_owner: "",
        pro_approver: "",
        pro_startDate: "",
        pro_endDate: "",
        pro_costCenter: "",
        pro_contractStatus: "",
        pro_customer_id: "",
        pro_status: "",
        pro_type: "",
        pro_totalHours: "",
        pro_contractRenewDate: "",
        pro_billingType: ""
    })

    useEffect(() => {
        if (projectEditId !== undefined) {
            loadSpecificProject();
        }
        else {
            //const [data, setData] = "";
        }
        loadAllEmployee();
        loadCustomer();
    }, []);

    const loadSpecificProject = async () => {
        var url = API_BASE_URL + `fetchspecificProject/` + projectEditId;
        axios.get(url, { headers: { 'x-access-token': `${API_TOKEN}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(res => {
                setData(res.data[0])
            },
                (error) => {
                    swal.fire({
                        text: error.response.data,
                        icon: "error",
                        type: "error"
                    });
                })
    }
    const loadAllEmployee = async () => {
        var url = API_BASE_URL + `fetchallemployee`;
        axios.get(url, { headers: { 'x-access-token': `${API_TOKEN}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(res => {
                setEmployees(res.data)
            },
                (error) => {
                    swal.fire({
                        text: error.response.data,
                        icon: "error",
                        type: "error"
                    });
                })
    }
    const loadCustomer = async () => {
        var url = API_BASE_URL + `fetchcustomer`;
        axios.post(url, {}, { headers: { 'x-access-token': `${API_TOKEN}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(res => {
                setCustomerData(res.data)
            },
                (error) => {
                    swal.fire({
                        text: error.response.data,
                        icon: "error",
                        type: "error"
                    });
                })
    }

    function handle(e) {
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }

    const handleFieldChange = event => {
        setData(data => ({
            ...data,
            [event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value
        }));
    };

    function submit(e) {
        e.preventDefault();
        setbtnLoading(true);
        var url = projectEditId ? API_BASE_URL + 'updateproject/' + projectEditId : API_BASE_URL + 'addproject';
        var method = projectEditId ? 'patch' : 'post';
        axios({ method, url, data, headers: { 'x-access-token': `${API_TOKEN}`, 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.status === 200) {
                    swal.fire({
                        text: "Successfully Saved",
                        icon: "success",
                        type: "success"
                    });
                    setbtnLoading(false);
                    handleClose();
                }
            })
            .catch(error => {
                swal.fire({
                    text: error.response.data,
                    icon: "error",
                    type: "error"
                });
                setbtnLoading(false);
            });
    }



    return (
        <MyDiv className="DrawerRight">
            <MyDiv className="grid-margin general-form stretch-card">
                <Card md={12}>
                    <Card.Header>
                        <HeadingFour className="card-title">
                            {projectEditId ? "Edit" : "Add"} Projects
                        </HeadingFour>
                    </Card.Header>
                    <Card.Body>
                        <MyDiv className="DrawerForm">
                            <form onSubmit={(e) => submit(e)}>
                                <Row className="DrawerFormField">
                                    <Col md={12}>
                                        <TextField required id="pro_name" label="Projects Name" value={data.pro_name} variant="standard" onChange={(e) => handle(e)} />
                                    </Col>
                                    {/* <Col md={12}>
                                        <TextField required id="pro_code" label="Projects Code" value={data.pro_code} variant="standard" onChange={(e) => handle(e)} />
                                    </Col> */}
                                    <Col md={12}>
                                        <TextField required id="pro_description" multiline rows={2} maxRows={4} label="Project Description" value={data.pro_description} variant="standard" onChange={(e) => handle(e)} />
                                    </Col>
                                    <Col md={12}>
                                        <TextField required id="pro_startDate" maxDate={new Date()} type="date" label="Project Start Date" value={data.pro_startDate} sx={{ width: 220 }} onChange={(e) => handle(e)} InputLabelProps={{ shrink: true, }} variant="standard" />
                                    </Col>
                                    <Col md={12}>
                                        <TextField required id="pro_endDate" maxDate={new Date()} type="date" label="Project End Date" value={data.pro_endDate} sx={{ width: 220 }} onChange={(e) => handle(e)} InputLabelProps={{ shrink: true, }} variant="standard" />
                                    </Col>
                                    <Col md={12}>
                                        <TextField select required name="pro_owner" id="pro_owner" variant="standard" label="Projects Owner" SelectProps={{ value: data.pro_owner, onChange: handleFieldChange }} >
                                            {employees.map((proEmployee) => (
                                                <MenuItem value={proEmployee.id}>{proEmployee.name}</MenuItem>
                                            ))}
                                        </TextField>
                                        {/* <TextField required id="pro_owner" label="Project Owner" value={data.pro_owner} variant="standard" onChange={(e) => handle(e)} /> */}
                                    </Col>
                                    <Col md={12}>
                                        <TextField select required name="pro_approver" id="pro_approver" variant="standard" label="Projects Approver" SelectProps={{ multiple: true, value: data.pro_approver || [], onChange: handleFieldChange }} >
                                            {employees.map((proEmployee) => (
                                                <MenuItem value={proEmployee.id}>{proEmployee.name}</MenuItem>
                                            ))}
                                        </TextField>
                                        {/* <TextField required id="pro_owner" label="Project Owner" value={data.pro_owner} variant="standard" onChange={(e) => handle(e)} /> */}
                                    </Col>
                                    <Col md={12}>
                                        <TextField select required name="pro_customer_id" id="pro_customer_id" variant="standard" label="Projects Customer" SelectProps={{ multiple: false, value: data.pro_customer_id || [], onChange: handleFieldChange }} >
                                            {customerData.map((customer) => (
                                                <MenuItem value={customer._id}>{customer.cus_company} - {customer.cus_name}</MenuItem>
                                            ))}
                                        </TextField>
                                        {/* <TextField required id="pro_owner" label="Project Owner" value={data.pro_owner} variant="standard" onChange={(e) => handle(e)} /> */}
                                    </Col>
                                    <Col md={12}>
                                        <TextField required id="pro_totalHours" label="Project Total Hours" value={data.pro_totalHours} variant="standard" onChange={(e) => handle(e)} />
                                    </Col>
                                    <Col md={12}>
                                        <TextField required id="pro_costCenter" label="Cost Center" value={data.pro_costCenter} variant="standard" onChange={(e) => handle(e)} />
                                    </Col>
                                    <Col md={12}>
                                        <TextField id="pro_contractRenewDate" maxDate={new Date()} label="Contract Renew Date" type="date" value={data.pro_contractRenewDate} sx={{ width: 220 }} onChange={(e) => handle(e)} InputLabelProps={{ shrink: true, }} variant="standard" />
                                    </Col>
                                    <Col md={12}>
                                        <TextField select required name="pro_billingType" id="pro_billingType" variant="standard" label="Billing Type" SelectProps={{ value: data.pro_billingType, onChange: handleFieldChange }} >
                                            {projectBillingType.map((proBillingType) => (
                                                <MenuItem value={proBillingType.value}>{proBillingType.label}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Col>
                                    <Col md={12}>
                                        <TextField select required name="pro_contractStatus" id="pro_contractStatus" variant="standard" label="Projects Contract Status" SelectProps={{ value: data.pro_contractStatus, onChange: handleFieldChange }} >
                                            {projectContractStatus.map((proContStatus) => (
                                                <MenuItem value={proContStatus.value}>{proContStatus.label}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Col>
                                    <Col md={12}>
                                        <TextField select required name="pro_status" id="pro_status" variant="standard" label="Projects Status" SelectProps={{ value: data.pro_status, onChange: handleFieldChange }} >
                                            {projectStatus.map((cusStatus) => (
                                                <MenuItem value={cusStatus.value}>{cusStatus.label}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Col>
                                    <Col md={12}>
                                        <TextField select required name="pro_type" id="pro_type" variant="standard" label="Projects Type" SelectProps={{ value: data.pro_type, onChange: handleFieldChange }} >
                                            {projectType.map((proType) => (
                                                <MenuItem value={proType.value}>{proType.label}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Col>
                                    <Col md={12} className="text-end">
                                        {btnLoading ?
                                            <Button variant="primary" disabled className='FormBtn'>
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className='mx-2' />
                                                Creating...
                                            </Button> :
                                            <button type="submit" className="FormBtn">Submit</button>
                                        }
                                    </Col>
                                </Row>
                            </form>
                        </MyDiv>
                    </Card.Body>
                </Card>
            </MyDiv>
        </MyDiv>
    );
}

export default FormProjects;